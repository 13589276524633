
import { Component, Prop, Vue } from "vue-property-decorator";

import TaskTask from "@/models/task/task";

import { phases } from "@/consts";
import SkillCategory from "@/models/skill/category";
import { IUserWorkplace } from "@/models/user/workplace";
import BlockBlock from "@/models/block/block";
import users from "@/api/routes/users";
import skillRoutes from "@/api/routes/skills";
import blockRoutes from "@/api/routes/blocks";
import TaskRoutes from "@/api/routes/tasks";
import UserUser from "@/models/user/user";
import TaskSkill from "@/models/task/skill";

interface IBlockTask {
  blocks?: {
    [id: string]: {
      id: string;
      full_name: string;
      skills: {
        [id: string]: TaskSkill;
      };
    };
  };
}

@Component
export default class CLevelSelect extends Vue {
  @Prop({ required: true }) protected user!: UserUser;
  @Prop({ required: false, default: false }) protected can_group?: boolean;
  @Prop({ required: false, default: false }) protected can_assignment?: boolean;
  @Prop({ required: false, default: false }) protected disabled?: boolean;
  @Prop({ required: false, default: () => new TaskTask() })
  protected currentTask!: TaskTask;

  protected phases = phases;

  protected user_blocks: IBlockTask = {};

  protected search: string = "";

  protected skillCategories: SkillCategory[] = [{ value: "Все" }];
  protected currentSkillCategory: SkillCategory = this.skillCategories[0];

  protected userWorkplaces: IUserWorkplace[] = [];
  protected currentUserWorkplace?: IUserWorkplace | null = null;

  protected blocks: BlockBlock[] = [{ id: "", full_name: "Все" }];
  protected currentBlock: BlockBlock = this.blocks[0];

  protected skillError: string = "";

  protected async created() {
    await Promise.all([
      this.loadUserWorkplaces(),
      this.loadSkillCategories(),
      this.loadBlocks()
    ]);
  }

  protected async loadUserWorkplaces() {
    return this.$api
      .get(users.workplacesMe, { params: { id: this.user!.id } })
      .then(async ({ data: res }: { data: IUserWorkplace[] }) => {
        this.userWorkplaces = res;

        this.currentUserWorkplace =
          this.userWorkplaces.find(w => w.is_main) || this.userWorkplaces[0];

        await this.getTaskSkills();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadSkillCategories() {
    return this.$api
      .get(skillRoutes.categories)
      .then(({ data: res }: { data: SkillCategory[] }) => {
        this.skillCategories.push(...res.slice(0, 3));
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadBlocks() {
    return this.$api
      .get(blockRoutes.blocks)
      .then(({ data: res }: { data: { blocks: BlockBlock[] } }) => {
        this.blocks.push(...res.blocks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async getTaskSkills() {
    return this.$api
      .get(TaskRoutes.task_skills, {
        params: {
          task_id: this.currentTask?.id,
          user_id: this.user!.id,
          search: this.search,
          category_id: this.currentSkillCategory!.id,
          block_id: this.currentBlock!.id,
          user_workplace_id: this.currentUserWorkplace!.id
        }
      })
      .then(({ data: res }: { data: IBlockTask }) => {
        const target = this.can_group ? this.user : this.currentTask;

        if (target.blocks) {
          Object.keys(target.blocks!.blocks!).forEach(key => {
            const block = target.blocks!.blocks![key];

            Object.keys(block.skills).forEach(skill_key => {
              const skill = block.skills[skill_key];

              if (res.blocks?.[key].skills?.[skill_key]) {
                res.blocks![key].skills![skill_key] = skill;
              }
            });
          });
        }

        this.user_blocks = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected updateSkill(block_id: string, skill: TaskSkill) {
    const target = this.can_group ? this.user : this.currentTask;

    if (target?.blocks?.blocks?.[block_id]) {
      target!.blocks!.blocks![block_id]!.skills![skill!.id!] = skill;
    } else {
      target!.blocks = target!.blocks ? target!.blocks! : { blocks: {} };

      target!.blocks!.blocks![block_id] = {
        skills: {
          [skill!.id!]: skill
        }
      };
    }

    this.$forceUpdate();
  }
}
