import "reflect-metadata";

import { IsBoolean, IsNumber, IsString, IsUUID } from "class-validator";

export default class TaskSkill {
  @IsUUID("4")
  public readonly id?: string;

  @IsUUID("4")
  public readonly skill_id!: string;

  @IsString()
  public skill_name?: string;

  @IsBoolean()
  public is_selected: boolean = false;

  @IsNumber()
  public positive_level: number = 0;

  @IsNumber()
  public negative_level: number = 0;

  @IsNumber()
  public current_level: number = 0;
}
