
import { Component, Prop, Vue } from "vue-property-decorator";

import { plainToClass } from "class-transformer";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import Upload from "@/components/Upload.vue";
import CFileDownload from "@/components/FileDownload.vue";

import TaskRoutes from "@/api/routes/tasks";

import TaskTask from "@/models/task/task";
import CComment from "@/components/Comment.vue";

interface ITaskData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly tasks: TaskTask[];
}

@Component({
  components: { CComment, CFileDownload, Upload, CInfiniteScroll }
})
export default class VTask extends Vue {
  @Prop({ required: true }) protected task_id!: string;

  protected currentTask: TaskTask | null = null;

  protected async created() {
    await this.getTask();
  }

  protected removeCommentFile(index: number) {
    this.currentTask?.student_comment_files.splice(index, 1);
  }

  protected uploadFile(file: string) {
    if (this.currentTask!.student_comment_files!.length < 10) {
      this.currentTask?.student_comment_files.push({ url: file });
    } else {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Не более 10 файлов",
        duration: 500,
        speed: 500
      });
    }
  }

  protected async getTask() {
    return this.$api
      .get(TaskRoutes.task(this.task_id))
      .then(({ data: res }: { data: TaskTask }) => {
        this.currentTask = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async toVerification() {
    return this.$api
      .put(TaskRoutes.to_verification(this.task_id), {
        student_comment: this.currentTask?.student_comment,
        student_comment_files: this.currentTask?.student_comment_files
      })
      .then(({ data: res }: { data: TaskTask }) => {
        this.currentTask = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
