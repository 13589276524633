
import { Component, Prop, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

import CFileDownload from "@/components/FileDownload.vue";
import Upload from "@/components/Upload.vue";
import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import CPaginatedSelect from "@/components/Select.vue";
import CLevelSelect from "@/views/tasks/components/LevelSelect.vue";
import CAssignmentUsers from "@/views/tasks/components/AssignmentUsers.vue";

import TaskRoutes from "@/api/routes/tasks";
import userRoutes from "@/api/routes/users";

import { ETaskTabs } from "@/enums/task_tabs";
import TaskTask, {
  taskCategories,
  taskGroupTasks,
  taskIndividualTabs
} from "@/models/task/task";

import UserUser from "@/models/user/user";
import { Tabs } from "@/models/task/tabs";
import TaskSkillBlock from "@/models/task/skillBlock";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

@Component({
  data: () => {
    return {
      ETaskTabs,
      taskCategories,
      ru
    };
  },
  components: {
    CFileDownload,
    Upload,
    CAssignmentUsers,
    CInfiniteScroll,
    CPaginatedSelect,
    CLevelSelect
  }
})
export default class CEditTask extends Vue {
  @Prop({ required: false }) protected user_id?: string;
  @Prop({ required: false, default: false }) protected can_group?: boolean;
  @Prop({ required: true, default: () => new TaskTask() })
  protected currentTask!: TaskTask;

  protected tabs: Tabs[] = this.can_group ? taskGroupTasks : taskIndividualTabs;
  protected currentTabValue: ETaskTabs = this.tabs[0].value;

  protected users: Map<string, UserUser> = new Map();

  protected currentUser: UserUser = new UserUser();

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected acceptFiles: string[] = [
    "application/pdf",
    "image/jpeg",
    "image/bmp",
    "image/png",
    "video/mp4",
    "audio/mpeg",
    "audio/wav",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
    "application/vnd.ms-excel.sheet.macroenabled.12", // xlsm MacOS|Linux
    "application/vnd.ms-excel.sheet.macroEnabled.12", //xlsm Win
    "application/vnd.openxmlformats-officedocument.presentationml.presentation", // pptx
    "application/msword", // doc
    "application/vnd.ms-excel", // xlx
    "application/vnd.ms-powerpoint", // ppt
    "application/vnd.oasis.opendocument.text", // odt
    "application/vnd.oasis.opendocument.spreadsheet", // ods
    "application/vnd.oasis.opendocument.presentation", // odp
    "text/plain", // txt
    "application/rtf" // rtf
  ];

  protected async created() {
    if (!this.can_group) {
      await this.loadUser();
    }
  }

  protected async loadUser() {
    return this.$api
      .get(userRoutes.user(this.user_id! || this.currentTask!.user_id!))
      .then(({ data: res }: { data: UserUser }) => {
        this.currentUser = res;
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadUsers(
    page: number = 0,
    search_phrase: string
  ): Promise<UserUser[]> {
    return this.$api
      .get(userRoutes.users, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          search: search_phrase
        }
      })
      .then(({ data: res }: { data: IData }) => {
        if (this.current_page !== res.current_page || this.current_page === 0) {
          this.current_page = res.current_page;

          this.next_page = res.next_page;

          if (res.users.length) {
            return res.users;
          } else {
            return [];
          }
        }

        return [];
      });
  }

  protected async createTask() {
    if (!this.can_group) {
      this.currentTask!.user_id = this.user_id!;
    }

    this.currentTask!.responsibles = [this.currentTask!.responsible!];

    return this.$api
      .post(TaskRoutes.tasks, { task: this.currentTask })
      .then(({ data: res }: { data: TaskTask }) => {
        this.$emit("create", res);

        this.hideEditModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async groupCreateTask() {
    this.currentTask!.responsibles = [this.currentTask!.responsible!];

    return this.$api
      .post(TaskRoutes.group_create, {
        task: this.currentTask,
        users: Array.from(this.users.values())
      })
      .then(() => {
        this.$emit("group_create", true);

        this.hideEditModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async updateTask() {
    this.currentTask!.responsibles = [this.currentTask!.responsible!];

    return this.$api
      .put(TaskRoutes.task(this.currentTask!.id), { task: this.currentTask })
      .then(({ data: res }: { data: TaskTask }) => {
        this.$emit("update", res);

        this.hideEditModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected attachUrl(event: any) {
    const url = event!.target!.value;
    if (!url || !url.startsWith("http")) {
      return;
    }

    event!.target!.value = "";

    this.uploadFile(url);
  }
  protected async uploadFile(url: string) {
    this.currentTask!.files.push({ url: url });
  }

  protected removeFile(index: number) {
    this.$delete(this.currentTask!.files!, index);
  }

  protected hideEditModal() {
    this.$emit("close", true);
  }
}
