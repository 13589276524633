
import { Component, Vue } from "vue-property-decorator";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import Upload from "@/components/Upload.vue";
import FileDownload from "@/components/FileDownload.vue";
import CSkillFilter from "@/components/SkillFilter.vue";

import TaskRoutes from "@/api/routes/tasks";

import TaskTask from "@/models/task/task";
import { examinationChildRoutes } from "@/router/examination";
import { toBlogDateTime } from "@/filters";

export interface ITaskData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly tasks: TaskTask[];
}

@Component({
  methods: { toBlogDateTime },
  components: { FileDownload, CSkillFilter, Upload, CInfiniteScroll }
})
export default class VTasks extends Vue {
  private currentUser: IJWTUser | null = this.$store.state.currentUser;

  protected tasks: TaskTask[] = [];

  protected per_page: number = 100;
  protected next_page: boolean = true;
  protected current_page: number = 0;

  private watchers: Function[] = [];

  private links = examinationChildRoutes.map(r => {
    return {
      title: r.meta!.name,
      to: {
        name: r.name
      }
    };
  });

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.currentUser = currentUser;
        }
      )
    );

    await this.clearUserTasks();
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async clearUserTasks() {
    this.current_page = 0;

    this.tasks = [];
    return this.getUserTasks();
  }

  protected async infiniteHandler() {
    if (this.next_page) {
      await this.getUserTasks(this.current_page + 1);
    }
  }

  protected async getUserTasks(page: number = 0) {
    return this.$api
      .get(TaskRoutes.tasks, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          user_id: this.currentUser!.id!
        }
      })
      .then(({ data: res }: { data: ITaskData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.tasks.push(...res.tasks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
