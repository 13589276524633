
import { Component, Prop, Vue } from "vue-property-decorator";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import CStructure, { IStructureProp } from "@/components/Structure.vue";
import CLevelSelect from "@/views/tasks/components/LevelSelect.vue";

import userRoutes from "@/api/routes/users";
import structureRoutes from "@/api/routes/structures";

import UserUser from "@/models/user/user";
import TaskTask from "@/models/task/task";
import StructureFactory from "@/models/structure/factory";
import { classToClass } from "class-transformer";

interface IData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly users: UserUser[];
}

@Component({ components: { CLevelSelect, CInfiniteScroll, CStructure } })
export default class CAssignmentUsers extends Vue {
  @Prop({ required: false, default: () => new Map() }) protected users!: Map<
    string,
    UserUser
  >;
  @Prop({ required: false, default: () => new TaskTask() })
  protected currentTask!: TaskTask;
  protected all_users: UserUser[] = [];

  protected per_page: number = 100;
  protected current_page: number = 0;
  protected next_page: boolean = false;

  protected search: string = "";

  protected factories: StructureFactory[] = [
    { id: "", full_name: "Все", skills: [] }
  ];
  protected factoryFilter: StructureFactory = this.factories[0];

  protected factory_id: string | null = null;
  protected factory_uec: boolean = false;
  protected structure: IStructureProp = {};

  protected async created() {
    await Promise.all([this.clearUsers(), this.loadFactories()]);
  }

  private infiniteHandler() {
    if (this.next_page) {
      this.loadUsers(this.current_page + 1);
    }
  }

  protected async clearUsers() {
    this.current_page = 0;
    this.all_users.splice(0, this.all_users.length);
    return await this.loadUsers();
  }

  protected async loadUsers(page: number = 0) {
    return this.$api
      .get(userRoutes.users_tree_search, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          factory_id: this.factoryFilter.id,
          infinity_id: this.structure.infinity_id,
          search: this.search,
          any_infinity: !this.structure.infinity_id
        }
      })
      .then(({ data: res }: { data: IData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;
        this.all_users.push(...res.users);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async loadFactories() {
    return this.$api
      .get(structureRoutes.factories)
      .then(({ data: res }: { data: StructureFactory[] }) => {
        this.factories.push(...res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async changeFactory() {
    this.structure = {};

    this.factoryFilter = this.factoryFilter ?? this.factories[0];
    this.factory_id = this.factoryFilter?.id!;
    this.factory_uec = this.factoryFilter?.uec!;

    await this.clearUsers();
  }

  protected async changeStructureFilter(structure: IStructureProp) {
    this.structure = structure;

    await this.clearUsers();
  }

  protected onUserChecked(user: UserUser) {
    if (this.users.get(user.id)) {
      this.users.delete(user.id);
    } else {
      this.users.set(user.id, classToClass(user));
    }
    this.$forceUpdate();
  }
}
