
import { Component, Prop, Vue } from "vue-property-decorator";

import { plainToClass } from "class-transformer";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import Upload from "@/components/Upload.vue";
import CFileDownload from "@/components/FileDownload.vue";
import CEditTask from "@/views/tasks/components/EditTask.vue";

import TaskRoutes from "@/api/routes/tasks";

import UserUser from "@/models/user/user";
import TaskTask from "@/models/task/task";

interface ITaskData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly tasks: TaskTask[];
}

@Component({
  components: { CFileDownload, Upload, CInfiniteScroll, CEditTask }
})
export default class CTasks extends Vue {
  @Prop({ required: true }) protected user!: UserUser;

  protected tasks: TaskTask[] = [];

  protected per_page: number = 100;
  protected next_page: boolean = true;
  protected current_page: number = 0;

  protected currentTask: TaskTask | null = null;
  protected currentTaskIndex: number = -1;

  protected async created() {
    await this.clearUserTasks();
  }

  protected async clearUserTasks() {
    this.current_page = 0;

    this.tasks = [];
    return this.getUserTasks();
  }

  protected async infiniteHandler() {
    if (this.next_page) {
      await this.getUserTasks(this.current_page + 1);
    }
  }

  protected async getUserTasks(page: number = 0) {
    return this.$api
      .get(TaskRoutes.tasks, {
        params: {
          page,
          per_page: this.per_page,
          paginate: true,
          user_id: this.user.id!
        }
      })
      .then(({ data: res }: { data: ITaskData }) => {
        this.current_page = res.current_page;
        this.next_page = res.next_page;

        this.tasks.push(...res.tasks);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async destroy() {
    return this.$api
      .destroy(TaskRoutes.task(this.currentTask!.id!))
      .then(() => {
        this.$delete(this.tasks, this.currentTaskIndex!);

        this.hideDeleteModal();
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async getTask(task_id: string) {
    return this.$api
      .get(TaskRoutes.task(task_id))
      .then(({ data: res }: { data: TaskTask }) => {
        this.currentTask = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async showEditModal(index: number | null) {
    if (index !== null) {
      await this.getTask(this.tasks[index].id);
      this.currentTaskIndex = index;
    } else {
      this.currentTask = new TaskTask();
    }

    this.$modal.show("TaskModal");
  }

  protected hideEditModal() {
    this.$modal.hide("TaskModal");
  }

  protected showDeleteModal(index: number) {
    this.currentTask = this.tasks[index];
    this.currentTaskIndex = index;

    this.$modal.show("deleteTask");
  }

  protected createdTask(task: TaskTask) {
    this.tasks.push(task);

    this.hideEditModal();
  }

  protected updatedTask(task: TaskTask) {
    this.$set(this.tasks, this.currentTaskIndex, task);

    this.hideEditModal();
  }

  protected hideDeleteModal() {
    this.$modal.hide("deleteTask");
  }
}
