
import { Component, Prop, Vue } from "vue-property-decorator";
import { ru } from "vuejs-datepicker/dist/locale";

import { plainToClass } from "class-transformer";

import CInfiniteScroll from "@/components/InfiniteScroll.vue";
import CFileDownload from "@/components/FileDownload.vue";

import TaskRoutes from "@/api/routes/tasks";

import TaskTask, { ETaskStatus } from "@/models/task/task";
import CComment from "@/components/Comment.vue";

interface ITaskData {
  readonly current_page: number;
  readonly next_page: boolean;
  readonly tasks: TaskTask[];
}

@Component({
  components: { CComment, CFileDownload, CInfiniteScroll },
  data: () => {
    return {
      ETaskStatus,
      ru
    };
  }
})
export default class VTask extends Vue {
  @Prop({ required: true }) protected task_id!: string;

  protected currentTask: TaskTask | null = null;

  protected async created() {
    await this.getTask();
  }

  protected removeCommentFile(index: number) {
    this.currentTask?.comment_files.splice(index, 1);
  }

  protected uploadFile(file: string) {
    if (this.currentTask!.comment_files!.length < 10) {
      this.currentTask?.comment_files.push({ url: file });
    } else {
      this.$notify({
        group: "notifications",
        type: "error",
        text: "Не более 10 файлов",
        duration: 500,
        speed: 500
      });
    }
  }

  protected async reAnswerTask() {
    this.currentTask!.parent_id = this.currentTask?.id;

    this.currentTask!.status = ETaskStatus.WORK;
    this.$modal.hide("ReAnswerTaskModal");

    return this.$api
      .post(TaskRoutes.tasks, { task: this.currentTask })
      .then(({ data: res }: { data: TaskTask }) => {
        this.$router.push({ name: "verify_task", params: { task_id: res.id } });
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async getTask() {
    return this.$api
      .get(TaskRoutes.task(this.task_id))
      .then(({ data: res }: { data: TaskTask }) => {
        this.currentTask = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  protected async verify(status: ETaskStatus) {
    return this.$api
      .put(TaskRoutes.verify(this.task_id), {
        status: status,
        comment: this.currentTask?.comment,
        comment_files: this.currentTask?.comment_files
      })
      .then(({ data: res }: { data: TaskTask }) => {
        this.currentTask = plainToClass(TaskTask, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }
}
